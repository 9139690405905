import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, Margin, } from '@components/atoms'
import { GridItem, Hero } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { validEmail, getCdnPath } from '@utils'
import { ContactForm, Input, Submit, Message } from '@components/forms'
import { Helmet } from 'react-helmet'


const ContactPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroImageConditional, 
    heroVideoConditional,
    heroFX,
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }

  const [sent, setSent] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [website, setWebsite] = React.useState('')
  const [company, setCompany] = React.useState('')
  const [how, setHow] = React.useState('')
  const [message, setMessage] = React.useState('')
  
  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    await fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify({ 'subject': 'The Working Assembly basic contact', email, name, borough: "", website, company, how, product: "" }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (response.status !== 200) {
        throw new Error("Bad response from server");
      }
      setMessage("Thanks for reaching out!")
      setSent(true)
    })
  }
  
  const [disabled, setDisabled] = React.useState(true)
  React.useEffect(() => {
    setDisabled(
      [email, name, company, how].includes("") || !validEmail(email)
    )
  }, [email, name, company, how])
  
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroFX }} />
      <StyledMargin>
        <Grid {...{ gridSnap }} >
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
          <ContactForm {...{ sent }} action="https://getform.io/f/derp" method="POST" id="contactForm" columns={8} >
            <Input type="text" placeholder="Name" name="name" value={name} onChange={e => setName(e.target.value)} required filled={name.length > 0} />
            <Input type="text" placeholder="Company" name="company" value={company} onChange={e => setCompany(e.target.value)} required filled={company.length > 0} />
            <Input type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} required filled={email.length > 0} />
            <Input type="text" placeholder="Website" name="website" value={website} onChange={e => setWebsite(e.target.value)} filled={website.length > 0} />
            <Input type="text" placeholder="How can we help you?" name="how" value={how} onChange={e => setHow(e.target.value)} required twoCol filled={how.length > 0} />
            <Submit {...{ disabled }}>
              <input type="submit" value="Send" onClick={e => submit(e)} />
            </Submit>
            {message && <Message>{message}</Message>}
          </ContactForm>
        </Grid>
      </StyledMargin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}



const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 100px 0px;
  a {
    text-decoration: none !important;
  }
  @media only screen and (max-width: 743px) {
    h1 {
      margin-bottom: 0.5em;
      max-width: 240px;
    }
  }
`


export const query = graphql`
  query ContactPageQuery {
    sanityPage(slug: {current: {eq: "contact"}}) {
      ...pageFields
    }
  }
`

export default ContactPage
